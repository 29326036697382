<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/admin">Admin</a>
          </li>
          <li class="breadcrumb-item active">
            Business Branding
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>Business Branding</h1>
          </div>
        </div>
        <div class="row row-has-panel">
          <div :class="isShowGotcha ? 'col-xl-9' : 'col-xl-12'">
            <div class="section-panel">
              <div
                class="section-panel-alert"
                data-alert="notification"
              >
                <div class="section-panel-alert-content">
                  <div class="icon">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <path
                        fill="currentColor"
                        d="M16,22 L8,22 C7.44771525,22 7,21.5522847 7,21 C7,20.4871642 7.38604019,20.0644928 7.88337887,20.0067277 L8,20 L11,20 L11,18 L4,18 C2.34314575,18 1,16.6568542 1,15 L1,5 C1,3.34314575 2.34314575,2 4,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,15 C23,16.6568542 21.6568542,18 20,18 L13,18 L13,20 L16,20 C16.5128358,20 16.9355072,20.3860402 16.9932723,20.8833789 L17,21 C17,21.5128358 16.6139598,21.9355072 16.1166211,21.9932723 L16,22 L8,22 L16,22 Z M20,4 L4,4 C3.44771525,4 3,4.44771525 3,5 L3,15 C3,15.5522847 3.44771525,16 4,16 L20,16 C20.5522847,16 21,15.5522847 21,15 L21,5 C21,4.44771525 20.5522847,4 20,4 Z"
                      />
                    </svg>
                  </div>
                  <div class="content">
                    <p>
                      Override personal user branding with business branding
                      <span
                        class="info-popover"
                        data-toggle="tooltip"
                        data-title="If enabled, all meeting rooms will display the same logo, hero image and welcome message"
                      >
                        <svg
                          width="16px"
                          height="16px"
                          viewBox="0 0 16 16"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                          <g
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <g
                              id="Dashboard-_-Admin-_-Business-branding"
                              transform="translate(-654.000000, -258.000000)"
                              fill="currentColor"
                              fill-rule="nonzero"
                            >
                              <g
                                id="Content"
                                transform="translate(260.000000, 108.000000)"
                              >
                                <g
                                  id="Notification"
                                  transform="translate(0.000000, 130.000000)"
                                >
                                  <path
                                    id="ic_info_popover"
                                    d="M402,20 C406.418278,20 410,23.581722 410,28 C410,32.418278 406.418278,36 402,36 C397.581722,36 394,32.418278 394,28 C394,23.581722 397.581722,20 402,20 Z M402,27 C401.447715,27 401,27.4477153 401,28 L401,28 L401,32 C401,32.5522847 401.447715,33 402,33 C402.552285,33 403,32.5522847 403,32 L403,32 L403,28 C403,27.4477153 402.552285,27 402,27 Z M402,23 C401.447715,23 401,23.4477153 401,24 C401,24.5522847 401.447715,25 402,25 C402.552285,25 403,24.5522847 403,24 C403,23.4477153 402.552285,23 402,23 Z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </p>
                  </div>
                  <div
                    v-if="brand && brand.level"
                    class="custom-checkbox-slide custom-checkbox-slide-invert ml-lg-auto mt-1 mt-lg-0"
                  >
                    <div v-if="canEditBranding">
                      <input
                        id="check10"
                        v-model="override_personal_brand"
                        type="checkbox"
                        @click.prevent="isShowConfirmOverride = true"
                      >

                      <label for="check10">{{ override_personal_brand ? 'Yes' : 'No' }}</label>
                    </div>
                    <div v-else>
                      <icon-locked
                        v-if="!canEditBranding"
                      />
                      <input
                        id="check10-disabled"
                        checked
                        type="checkbox"
                        @click.prevent="$emit('show-premium-feature-popup')"
                      >

                      <label for="check10-disabled">Yes</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Brand info</h3>
                </div>
                <div
                  v-if="canEditBranding"
                  class="section-panel-header-action"
                >
                  <a
                    v-if="statusBrand"
                    href="#"
                    class="btn btn-link"
                    @click.prevent="discardBrandInfo"
                  >
                    <icon-cross class="mr-2 d-flex" /> Cancel &amp; Discard
                  </a>
                  <a
                    v-else
                    href="#"
                    class="btn btn-link"
                    @click.prevent="statusBrand = true"
                  >
                    <icon-edit class="mr-2 d-flex" />Edit
                  </a>
                </div>
                <div
                  v-else
                  class="section-panel-header-action"
                >
                  <a
                    class="btn btn-link cursor-pointer"
                    @click="$emit('show-premium-feature-popup')"
                  >
                    <icon-locked class="mr-2 d-flex" />Edit
                  </a>
                </div>
              </div>
              <div class="section-panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="col-lg-8">
                      <div
                        class="form-group"
                        :class="{'form-error': errors.guest_welcome_message}"
                      >
                        <label>
                          Guest welcome message
                          <span
                            class="info-popover"
                            data-toggle="tooltip"
                            data-title="Used in welcome page as a greeting"
                          >
                            <svg
                              width="16px"
                              height="16px"
                              viewBox="0 0 16 16"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                              <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <g
                                  id="Dashboard-_-Admin-_-Business-branding"
                                  transform="translate(-654.000000, -258.000000)"
                                  fill="currentColor"
                                  fill-rule="nonzero"
                                >
                                  <g
                                    id="Content"
                                    transform="translate(260.000000, 108.000000)"
                                  >
                                    <g
                                      id="Notification"
                                      transform="translate(0.000000, 130.000000)"
                                    >
                                      <path
                                        id="ic_info_popover"
                                        d="M402,20 C406.418278,20 410,23.581722 410,28 C410,32.418278 406.418278,36 402,36 C397.581722,36 394,32.418278 394,28 C394,23.581722 397.581722,20 402,20 Z M402,27 C401.447715,27 401,27.4477153 401,28 L401,28 L401,32 C401,32.5522847 401.447715,33 402,33 C402.552285,33 403,32.5522847 403,32 L403,32 L403,28 C403,27.4477153 402.552285,27 402,27 Z M402,23 C401.447715,23 401,23.4477153 401,24 C401,24.5522847 401.447715,25 402,25 C402.552285,25 403,24.5522847 403,24 C403,23.4477153 402.552285,23 402,23 Z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </label>
                        <input
                          v-model="guest_welcome_message"
                          type="text"
                          class="form-control"
                          placeholder="Welcome Message"
                          :disabled="!statusBrand"
                        >
                        <div
                          v-for="(error, index) in errors.guest_welcome_message"
                          :key="index"
                          class="form-error-message"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="statusBrand"
                      class="col-lg-4 ml-auto"
                    >
                      <div class="form-group text-md-right">
                        <label class="d-none d-lg-block">&nbsp;</label>
                        <button
                          type="submit"
                          class="btn btn-md btn-primary"
                          @click.prevent="updateBrandInfo()"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Colors</h3>
                </div>
                <div
                  v-if="canEditBranding"
                  class="section-panel-header-action"
                >
                  <a
                    v-if="statusColors"
                    href="#"
                    class="btn btn-link"
                    @click.prevent="discardColors"
                  >
                    <icon-cross class="mr-2 d-flex" /> Cancel &amp; Discard
                  </a>
                  <a
                    v-else
                    href="#"
                    class="btn btn-link"
                    @click.prevent="statusColors = true"
                  >
                    <icon-edit class="mr-2 d-flex" />Edit
                  </a>
                </div>
                <div
                  v-else
                  class="section-panel-header-action"
                >
                  <a
                    class="btn btn-link cursor-pointer"
                    @click="$emit('show-premium-feature-popup')"
                  >
                    <icon-locked class="mr-2 d-flex" />Edit
                  </a>
                </div>
              </div>
              <div class="section-panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="col-lg-3">
                      <div
                        class="form-group with-color-picker"
                        :class="{'form-error': errors.primary_color_hex}"
                      >
                        <label>
                          Primary color
                          <span
                            class="info-popover"
                            data-toggle="tooltip"
                            data-title="Change button colors within welcome page and invite process"
                          >
                            <svg
                              width="16px"
                              height="16px"
                              viewBox="0 0 16 16"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                              <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <g
                                  id="Dashboard-_-Admin-_-Business-branding"
                                  transform="translate(-654.000000, -258.000000)"
                                  fill="currentColor"
                                  fill-rule="nonzero"
                                >
                                  <g
                                    id="Content"
                                    transform="translate(260.000000, 108.000000)"
                                  >
                                    <g
                                      id="Notification"
                                      transform="translate(0.000000, 130.000000)"
                                    >
                                      <path
                                        id="ic_info_popover"
                                        d="M402,20 C406.418278,20 410,23.581722 410,28 C410,32.418278 406.418278,36 402,36 C397.581722,36 394,32.418278 394,28 C394,23.581722 397.581722,20 402,20 Z M402,27 C401.447715,27 401,27.4477153 401,28 L401,28 L401,32 C401,32.5522847 401.447715,33 402,33 C402.552285,33 403,32.5522847 403,32 L403,32 L403,28 C403,27.4477153 402.552285,27 402,27 Z M402,23 C401.447715,23 401,23.4477153 401,24 C401,24.5522847 401.447715,25 402,25 C402.552285,25 403,24.5522847 403,24 C403,23.4477153 402.552285,23 402,23 Z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </label>
                        <input
                          id="txt-primary-color"
                          ref="txt-primary-color"
                          v-model="primary_color_hex"
                          type="text"
                          class="form-control"
                          :disabled="!statusColors"
                          readonly
                          @click="showPrimaryColorPicker"
                        >
                        <div
                          v-click-outside="{
                            exclude: ['txt-primary-color'],
                            handler: 'hidePrimaryColorPicker'
                          }"
                          class="color-picker-container"
                        >
                          <label
                            v-if="primary_color_hex"
                            class="current-color"
                            for="txt-primary-color"
                            :style="`background-color: ${primary_color_hex}`"
                          />
                          <chrome-picker
                            v-if="isPrimaryShow"
                            v-model="primaryColors"
                            @input="onChangePrimary"
                          />
                        </div>
                        <!-- <div class="color-edit-icon" v-if="statusColors">
                                                    <a href="#" class="fas fa-eye-dropper" aria-hidden="true" @click.prevent="showPrimaryColorPicker"></a>
                                                </div> -->
                        <div
                          v-for="(error, index) in errors.primary_color_hex"
                          :key="index"
                          class="form-error-message"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div
                        class="form-group with-color-picker"
                        :class="{'form-error': errors.secondary_color_hex}"
                      >
                        <label>
                          Secondary color
                          <span
                            class="info-popover"
                            data-toggle="tooltip"
                            data-title="Change button colors within welcome page and invite process"
                          >
                            <svg
                              width="16px"
                              height="16px"
                              viewBox="0 0 16 16"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                              <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <g
                                  id="Dashboard-_-Admin-_-Business-branding"
                                  transform="translate(-654.000000, -258.000000)"
                                  fill="currentColor"
                                  fill-rule="nonzero"
                                >
                                  <g
                                    id="Content"
                                    transform="translate(260.000000, 108.000000)"
                                  >
                                    <g
                                      id="Notification"
                                      transform="translate(0.000000, 130.000000)"
                                    >
                                      <path
                                        id="ic_info_popover"
                                        d="M402,20 C406.418278,20 410,23.581722 410,28 C410,32.418278 406.418278,36 402,36 C397.581722,36 394,32.418278 394,28 C394,23.581722 397.581722,20 402,20 Z M402,27 C401.447715,27 401,27.4477153 401,28 L401,28 L401,32 C401,32.5522847 401.447715,33 402,33 C402.552285,33 403,32.5522847 403,32 L403,32 L403,28 C403,27.4477153 402.552285,27 402,27 Z M402,23 C401.447715,23 401,23.4477153 401,24 C401,24.5522847 401.447715,25 402,25 C402.552285,25 403,24.5522847 403,24 C403,23.4477153 402.552285,23 402,23 Z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </label>
                        <input
                          id="txt-second-color"
                          ref="txt-second-color"
                          v-model="secondary_color_hex"
                          type="text"
                          class="form-control"
                          :disabled="!statusColors"
                          readonly
                          @click="showSecondColorPicker"
                        >
                        <div
                          v-click-outside="{
                            exclude: ['txt-second-color'],
                            handler: 'hideSecondColorPicker'
                          }"
                          class="color-picker-container"
                        >
                          <label
                            v-if="secondary_color_hex"
                            class="current-color"
                            for="txt-second-color"
                            :style="`background-color: ${secondary_color_hex}`"
                          />
                          <chrome-picker
                            v-if="isSecondShow"
                            v-model="secondColors"
                            @input="onChangeSecond"
                          />
                        </div>
                        <!-- <div class="color-edit-icon" v-if="statusColors">
                                                    <a href="#" class="fas fa-eye-dropper" aria-hidden="true" @click.prevent="showSecondColorPicker"></a>
                                                </div> -->
                        <div
                          v-for="(error, index) in errors.secondary_color_hex"
                          :key="index"
                          class="form-error-message"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="statusColors"
                      class="col-lg-3"
                    >
                      <a
                        href="#"
                        class="d-block mb-1 mb-lg-0 mt-lg-5"
                        @click.prevent="restoreDefaultColors"
                      >
                        Set default colors</a>
                    </div>
                    <div
                      v-if="statusColors"
                      class="col-lg-3 ml-auto"
                    >
                      <div class="form-group text-md-right">
                        <label class="d-none d-lg-block">&nbsp;</label>
                        <button
                          type="submit"
                          class="btn btn-md btn-primary"
                          @click.prevent="updateColors()"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Visual materials</h3>
                </div>
                <div
                  v-if="canEditBranding"
                  class="section-panel-header-action"
                >
                  <a
                    v-if="statusImages"
                    href="#"
                    class="btn btn-link"
                    @click.prevent="discardImages"
                  >
                    <icon-cross class="mr-2 d-flex" /> Cancel &amp; Discard
                  </a>
                  <a
                    v-else
                    href="#"
                    class="btn btn-link"
                    @click.prevent="statusImages = true"
                  >
                    <icon-edit class="mr-2 d-flex" />Edit
                  </a>
                </div>
                <div
                  v-else
                  class="section-panel-header-action"
                >
                  <a
                    class="btn btn-link cursor-pointer"
                    @click="$emit('show-premium-feature-popup')"
                  >
                    <icon-locked class="mr-2 d-flex" />Edit
                  </a>
                </div>
              </div>
              <div class="section-panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="col-lg flex-lg-grow-0 mb-1 mb-lg-0">
                      <fieldset class="form-group-upload">
                        <label
                          v-if="logo"
                          for="logo"
                          class="real-image"
                        >
                          <img
                            id="logo_image"
                            alt="Upload"
                            :src="logo"
                          >
                        </label>
                        <label
                          v-else
                          for="logo"
                          class="place-holder"
                        >
                          <span class="p-3">
                            <icon-upload />
                            <!-- <span>Drag &amp; Drop file here</span> -->
                          </span>
                        </label>
                        <input
                          id="logo"
                          name="logo"
                          type="file"
                          :disabled="!statusImages"
                          @change="onLogoFileSelected"
                        >
                      </fieldset>
                    </div>
                    <div class="col-lg form-group-logo-content">
                      <h4>Brand logo</h4>
                      <p>
                        Visible on the welcome screen for all meetings, during invite process and other places.
                      </p>
                      <p>
                        We accept jpeg, png, svg.
                      </p>
                      <p
                        v-for="(error, index) in errors.logo"
                        :key="index"
                        class="form-error-message"
                      >
                        {{ error }}
                      </p>
                      <label
                        v-if="isLogoFileDeletable && statusImages"
                        class="btn btn-outline"
                        @click.prevent="confirmDeleteImage('logo')"
                      ><i class="far fa-trash-alt text-danger" /></label>
                      <label
                        v-if="statusImages"
                        for="logo"
                        class="btn btn-outline"
                      >Choose</label>
                    </div>
                    <div class="col-lg form-group-recommended">
                      <h5>Recommendation</h5>
                      <img
                        v-svg-inline
                        src="@/assets/img/logo_mockup.svg"
                        class="logo stroke"
                      >
                      <p>The logo asset should be in a landscape aspect, with whitespace around the brand elements</p>
                      <p>Dimensions: <strong>width of 250px</strong> and a height greater than 30px</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-lg flex-lg-grow-0 mb-1 mb-lg-0">
                      <fieldset class="form-group-upload">
                        <label
                          v-if="hero_image"
                          for="hero_image"
                          class="real-image"
                        >
                          <span>
                            <img
                              alt="Upload"
                              :src="hero_image"
                            >
                          </span>
                        </label>
                        <label
                          v-else
                          for="hero_image"
                          class="place-holder"
                        >
                          <span class="p-3">
                            <icon-upload />
                            <!-- <span>Drag &amp; Drop file here</span> -->
                          </span>
                        </label>
                        <input
                          id="hero_image"
                          name="hero_image"
                          type="file"
                          :disabled="!statusImages"
                          @change="onHeroImageFileSelected"
                        >
                      </fieldset>
                    </div>
                    <div class="col-lg">
                      <div class="form-group-logo-content">
                        <h4>Hero image</h4>
                        <p>
                          Visible on the welcome screen for all meetings and other places.
                        </p>
                        <p>
                          We accept jpeg, png, svg.
                        </p>
                        <label
                          v-if="isHeroImageFileDeletable && statusImages"
                          class="btn btn-outline"
                          @click.prevent="confirmDeleteImage('hero_image')"
                        ><i class="far fa-trash-alt text-danger" /></label>
                        <label
                          v-if="statusImages"
                          for="hero_image"
                          class="btn btn-outline"
                        >Choose</label>
                      </div>
                    </div>
                    <div class="col-lg form-group-recommended">
                      <h5>Recommendation</h5>
                      <img
                        v-svg-inline
                        src="@/assets/img/hero_mockup.svg"
                        class="float hero stroke"
                      >
                      <p>Image should be in a portrait aspect, with a central focal point as the edges may get cropped</p>
                      <p>Dimensions: width greater than 600px</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row align-items-center">
                    <div class="col-lg flex-lg-grow-0 mb-1 mb-lg-0">
                      <div class="form-group-upload">
                        <label
                          v-if="faviconUrlPreview"
                          for="favicon"
                          class="real-image"
                        >
                          <span>
                            <img
                              alt="Upload"
                              :src="faviconUrlPreview"
                            >
                          </span>
                        </label>
                        <label
                          v-else
                          for="favicon"
                          class="place-holder"
                        >
                          <span class="p-3">
                            <icon-upload />
                            <!-- <span>Drag &amp; Drop file here</span> -->
                          </span>
                        </label>
                        <input
                          id="favicon"
                          name="favicon"
                          type="file"
                          :disabled="!statusImages || !isShowFaviconUpload"
                          @change="onFaviconFileSelected"
                        >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <h4>
                        Favicon
                      </h4>
                      <p class="mb-1">
                        Enter your web domain here and we will grab your favicon.
                        <br>
                        <span v-if="!isShowFaviconUpload">
                          Alternatively, you can
                          <a
                            v-if="statusImages"
                            class="cursor-pointer"
                            @click="toggleFaviconInputMethod"
                          >
                            upload .ico file.
                          </a>
                          <span v-else>upload .ico file.</span>
                        </span>
                        <span v-if="isShowFaviconUpload">
                          Alternatively, you can
                          <a
                            v-if="statusImages"
                            class="cursor-pointer"
                            @click="toggleFaviconInputMethod"
                          >
                            use your domain.
                          </a>
                          <span v-else>use your domain.</span>
                        </span>
                      </p>

                      <div
                        v-if="!isShowFaviconUpload"
                        class="form-group"
                        :class="{'form-error': errors.favicon_url}"
                      >
                        <!--
                                                <label v-if="statusImages && isFaviconFileDeletable" class="btn btn-outline" @click.prevent="confirmDeleteImage('favicon')"><i class="far fa-trash-alt text-danger"/></label>
                                                -->
                        <input
                          v-model="favicon_url"
                          type="text"
                          class="form-control"
                          placeholder="Your domain"
                          :disabled="!statusImages"
                        >
                        <div
                          v-for="(error, index) in errors.favicon_url"
                          :key="index"
                          class="form-error-message"
                        >
                          {{ error }}
                        </div>
                      </div>

                      <div v-if="statusImages && isShowFaviconUpload">
                        <label
                          v-if="isFaviconFileDeletable"
                          class="btn btn-outline"
                          @click.prevent="confirmDeleteImage('favicon')"
                        ><i class="far fa-trash-alt text-danger" /></label>
                        <label
                          for="favicon"
                          class="btn btn-outline"
                        >Choose file</label>
                        <div
                          v-for="(error, index) in errors.favicon"
                          :key="index"
                          class="form-error-message"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="statusImages"
                      class="col-lg ml-auto align-self-end"
                    >
                      <div class="form-group text-md-right">
                        <label class="d-none d-lg-block">&nbsp;</label>
                        <button
                          type="submit"
                          class="btn btn-md btn-primary"
                          @click.prevent="updateImages()"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              v-if="brand && brand.level && canEditBranding"
              class="text-right mt-1"
            >
              <button
                class="btn btn-sm btn-gray"
                @click="isShowConfirmRestoreDefaults = true"
              >
                Restore default
              </button>
            </div>
            <div
              v-else
              class="text-right mt-1"
            >
              <button class="btn btn-sm btn-gray disabled cursor-initial">
                Restore default
              </button>
            </div>
          </div>
          <div
            v-if="isShowGotcha"
            class="col-xl mt-2 mt-xl-0"
          >
            <gotcha
              :tip-list="tipList"
              @close="isShowGotcha = false"
            />
          </div>
        </div>
      </div>
    </section>

    <confirm-dialog
      v-if="isShowConfirmDeleteImage"
      title="Are you sure you want to delete this image?"
      description="You will not be able to recover it later"
      @no="cancelDeleteImage"
      @yes="deleteImage"
    />

    <confirm-dialog
      v-if="isShowConfirmOverride"
      title="Confirmation"
      :description="'Are you sure you want to '+(override_personal_brand ? 'disable' : 'enable')+' override?'"
      @no="isShowConfirmOverride = false"
      @yes="toggleOverridePersonalBrand"
    />
    <confirm-dialog
      v-if="isShowConfirmRestoreDefaults"
      title="Confirmation"
      :description="'Are you sure you want to restore default branding? This will restore colors, images and greeting.'"
      @no="isShowConfirmRestoreDefaults = false"
      @yes="toggleRestoreDefaults"
    />
  </main>
</template>
<script>
import {brandingStore} from '@/store/__STORE_branding';
import {userStore} from '@/store/__STORE_user';
import {mainStore} from '@/store/__STORE_main';
import {Chrome} from 'vue-color';
import Gotcha from '@/components/Gotcha.vue';
import PlanMixin from '@/mixins/PlanMixin';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import IconEdit from '@/components/icons/IconEdit.vue';
import IconCross from '@/components/icons/IconCross.vue';
import IconUpload from '@/components/icons/IconUpload.vue';
import IconLocked from '@/components/icons/IconLocked.vue';
import axios from 'axios';
import BrandingMixin from '@/mixins/BrandingMixin';

export default {
  components: {
    'chrome-picker': Chrome,
    ConfirmDialog,
    Gotcha,
    IconEdit,
    IconCross,
    IconUpload,
    IconLocked,
  },
  mixins: [PlanMixin, BrandingMixin],
  data() {
    return {
      statusBrand: false,
      statusColors: false,
      statusImages: false,
      isShowFaviconUpload: false,
      override_personal_brand: true,
      guest_welcome_message: '',
      business_name: '',
      subdomain: '',
      primary_color_hex: '',
      secondary_color_hex: '',
      favicon: '',
      favicon_url: '',
      original_favicon_url: '',
      logo: '',
      hero_image: '',
      faviconFile: '',
      logoFile: '',
      heroImageFile: '',
      isFaviconFileDeletable: false,
      isLogoFileDeletable: false,
      isHeroImageFileDeletable: false,
      isPrimaryShow: false,
      isSecondShow: false,
      primaryColors: '',
      secondColors: '',
      isShowGotcha: true,
      tipList: [
        'Business branding gives more options than Personal branding',
      ],
      errors: [],
      isShowConfirmDeleteImage: false,
      isShowConfirmOverride: false,
      isShowConfirmRestoreDefaults: false,
      imageToBeDeleted: '',
    };
  },
  computed: {

    brand: () => brandingStore.businessBrand,

    faviconUrlPreview() {
      if ((this.original_favicon_url !== this.favicon_url) && this.isValidURL(this.favicon_url)) {
        return 'https://www.google.com/s2/favicons?sz=32&domain_url=' + this.favicon_url;
      }

      return this.favicon;
    },
  },
  mounted() {
    // eslint-disable-next-line
        $('[data-toggle="tooltip"]').tooltip({placement: 'top'})
  },
  created() {
    if (this.authUserLoadedAndCannotAccessAdminFeatures) {
      this.$router.push({name: 'dashboard'});
      return;
    }

    brandingStore.getBusinessBrand()
        .then(() => {
          this.initBrand();
        });
  },
  methods: {
    initBrand() {
      this.initBrandInfo();
      this.initColors();
      this.initImages();
    },
    initBrandInfo() {
      this.override_personal_brand = this.brand.override_personal_brand;
      this.guest_welcome_message = this.brand.guest_welcome_message;
      this.subdomain = this.brand.subdomain;
      this.business_name = this.brand.business_name;
      this.errors = [];
    },
    initColors() {
      this.primary_color_hex = this.brand.primary_color_hex;
      this.secondary_color_hex = this.brand.secondary_color_hex;
      this.primaryColors = {hex: this.primary_color_hex};
      this.secondColors = {hex: this.secondary_color_hex};
      this.isPrimaryShow = false;
      this.isSecondShow = false;
    },
    initImages() {
      this.logo = this.brand.logo;
      this.favicon = this.brand.favicon;
      this.favicon_url = this.brand.favicon_url;

      this.original_favicon_url = this.brand.favicon_url;
      this.hero_image = this.brand.hero_image;

      this.isFaviconFileDeletable = !!this.favicon;
      this.isLogoFileDeletable = !!this.logo;
      this.isHeroImageFileDeletable = !!this.hero_image;
    },
    discardBrandInfo() {
      this.statusBrand = false;
      this.initBrandInfo();
    },
    discardColors() {
      this.statusColors = false;
      this.initColors();
    },
    discardImages() {
      this.statusImages = false;
      this.isShowFaviconUpload = false;
      this.initImages();
    },
    updateBrandInfo() {
      const vm = this;
      vm.errors = [];
      const flagError = false;

      if (flagError) return;
      const param = {
        guest_welcome_message: vm.guest_welcome_message,
        business_name: vm.business_name,
        subdomain: vm.subdomain,
      };
      brandingStore.updateBrandInfo(param)
          .then(() => {
            vm.$toast.success('Brand updated successfully!');
            vm.statusBrand = false;
          })
          .catch((err) => {
            try {
              const data= err.response.data;
              vm.$toast.error(data.message);
              if (err.response.status === 422) {
                vm.errors = data.errors;
              }
            } catch {
              vm.$toast.error(err);
            }
          });
    },
    updateColors() {
      const vm = this;
      let flagError = false;
      vm.errors = [];
      if (!this.primary_color_hex) {
        vm.errors.primary_color_hex = ['Please select primary color!'];
        flagError = true;
      }
      if (!this.secondary_color_hex) {
        vm.errors.secondary_color_hex = ['Please select secondary color!'];
        flagError = true;
      }
      if (flagError) return;
      const param = {
        primary_color_hex: vm.primary_color_hex,
        secondary_color_hex: vm.secondary_color_hex,
      };
      brandingStore.updateBrandColors(param)
          .then(() => {
            userStore.getUserProfile().then(() => {
              vm.$toast.success('Colors updated successfully!');
              vm.statusColors = false;
            });
          })
          .catch((err) => {
            try {
              const data= err.response.data;
              vm.$toast.error(data.message);
              if (data.response.errors) {
                vm.errors = data.errors;
              }
            } catch {
              vm.$toast.error(err);
            }
          });
    },
    updateImages() {
      const vm = this;
      vm.errors = [];
      const param = new FormData();
      if (vm.logoFile) {
        param.append('logo', vm.logoFile);
      }
      if (vm.faviconFile && vm.isShowFaviconUpload) {
        param.append('favicon', vm.faviconFile);
        param.append('favicon_url', '');
      }
      if (vm.heroImageFile) {
        param.append('hero_image', vm.heroImageFile);
      }

      if (!vm.isShowFaviconUpload) {
        param.append('favicon_url', vm.favicon_url);
      }

      brandingStore.updateBrandImages(param)
          .then(() => {
            userStore.getUserProfile().then(() => {
            });
            vm.$toast.success('Visual materials updated successfully!');
            vm.statusImages = false;

            vm.isFaviconFileDeletable = !!vm.favicon;
            vm.isLogoFileDeletable = !!vm.logo;
            vm.isHeroImageFileDeletable = !!vm.hero_image;
            if (vm.faviconFile) {
              vm.favicon_url = '';
            }

            vm.logoFile = '';
            vm.faviconFile = '';
            vm.heroImageFile = '';
            vm.isShowFaviconUpload = false;
          })
          .catch((err) => {
            try {
              const data= err.response.data;
              vm.$toast.error(data.message);
              if (err.response.status === 422) {
                vm.errors = data.errors;
              }
            } catch {
              return;
            }
          });
    },
    toggleOverridePersonalBrand() {
      mainStore.setLoading();
      axios.post('/override-personal-brand', {override_personal_brand: this.override_personal_brand ? 0 : 1})
          .then(() => {
            userStore.getUserProfile().then(() => {
              this.override_personal_brand = !this.override_personal_brand;
              mainStore.setStatus('');
              this.isShowConfirmOverride = false;
            });
          })
          .catch((error) => {
            this.isShowConfirmOverride = false;
            mainStore.setStatus('');
            this.$toast.error('Error.');
          });
    },
    toggleRestoreDefaults() {
      mainStore.setLoading();
      axios.post('/business-brand/restore-default-branding')
          .then(async () => {
            await brandingStore.getBusinessBrand();
            this.initBrand();
            await userStore.getUserProfile();
            mainStore.setStatus('');
            this.statusBrand = false;
            this.statusImages = false;
            this.statusColors = false;
            this.isShowConfirmRestoreDefaults = false;
          })
          .catch((error) => {
            mainStore.setStatus('');
            this.$toast.error('Error.');
          });
    },
    restoreDefaultColors() {
      this.primary_color_hex = brandingStore.__GETTERdefaultPrimary;
      this.secondary_color_hex = brandingStore.__GETTERdefaultSecondary;
      this.primaryColors = {hex: this.primary_color_hex};
      this.secondColors = {hex: this.secondary_color_hex};
      this.isPrimaryShow = false;
      this.isSecondShow = false;
    },
    confirmDeleteImage(image) {
      this.imageToBeDeleted = image;
      this.isShowConfirmDeleteImage = true;
    },
    cancelDeleteImage() {
      this.isShowConfirmDeleteImage = false;
      this.imageToBeDeleted = '';
    },
    deleteImage() {
      mainStore.setLoading();
      axios({url: '/business-brand-image/'+this.imageToBeDeleted, method: 'DELETE'})
          .then(() => {
            mainStore.setStatus('');
            userStore.getUserProfile();
            brandingStore.getBusinessBrand()
                .then(() => {
                  this.isShowConfirmDeleteImage = false;
                  this.initBrand();
                  this.$toast.success('Image deleted');
                });
          })
          .catch((err) => {
            mainStore.setStatus('');

            this.$toast.error('Error');
          });
    },
    onLogoFileSelected(event) {
      this.logoFile = event.target.files[0];
      this.logo = URL.createObjectURL(this.logoFile);
    },
    onFaviconFileSelected(event) {
      this.faviconFile = event.target.files[0];
      this.favicon = URL.createObjectURL(this.faviconFile);
    },
    onHeroImageFileSelected(event) {
      this.heroImageFile = event.target.files[0];
      this.hero_image = URL.createObjectURL(this.heroImageFile);
    },
    onChangePrimary(val) {
      if (this.statusColors) {
        this.primary_color_hex = val.hex;
        this.primaryColors = val;
      }
    },
    onChangeSecond(val) {
      if (this.statusColors) {
        this.secondColors = val;
        this.secondary_color_hex = val.hex;
      }
    },
    showSecondColorPicker() {
      if (this.statusColors) {
        this.isSecondShow = !this.isSecondShow;
      }
    },
    showPrimaryColorPicker() {
      if (this.statusColors) {
        this.isPrimaryShow = !this.isPrimaryShow;
      }
    },
    hideSecondColorPicker() {
      if (this.statusColors && this.isSecondShow) {
        this.isSecondShow = false;
      }
    },
    hidePrimaryColorPicker() {
      if (this.statusColors && this.isPrimaryShow) {
        this.isPrimaryShow = false;
      }
    },
    isValidURL(string) {
      // eslint-disable-next-line
            let res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      return (res !== null);
    },
    toggleFaviconInputMethod() {
      this.favicon = this.brand.favicon;
      this.favicon_url = this.brand.favicon_url;

      // this.favicon = '';
      // this.faviconFile = '';
      // this.favicon_url = '';
      this.errors = [];
      this.isShowFaviconUpload = !this.isShowFaviconUpload;
    },
  },

};
</script>

<style lang="scss" scoped>
  #logo_image{
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  .form-group-logo-content {
    flex: 1 0;
    max-width: 100%;
  }

  h4 {
    font-size: 2.4rem;
  }

  .form-group-recommended {
    flex: 1 1;

    h5 {
      font-size: 1.7rem;
      font-weight: 400;
      white-space: nowrap;
    }

     ::v-deep svg {
      margin: 1rem 0;
      width: 70%;

      &.logo {
        max-width: 250px;
      }

      &.hero {
        max-width: 150px;
      }

      &.float {
        float: left;
        margin-right: 1rem;
        width: 45%;

        + p {
          margin-top: 1rem;
        }
      }
    }

    p {
      font-size: 1.36rem;
    }
  }
</style>
